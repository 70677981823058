import NotificationService from "../NotificationService";
import adminServices from "../services/httpServices";

export const onFinishHandlers = async(values,form,setFirstFormDetails,setCurrent,current) => {
    
    if (await form.validateFields({ validateOnly: true })) {
      setFirstFormDetails(values)
      setCurrent(current + 1);
    }
    return 
  };

 export const imageUpload = async (uploadedimage,setImagePath,setisloading) => {
    if (uploadedimage) {
      setisloading(true);
      try {
        let data = new FormData();
        data.append("File", uploadedimage);
        const res = await adminServices.fileUpload(data);
        // console.log(res);
        setImagePath(res.data?.path);
        setisloading(false);
      } catch (error) {
        NotificationService.show(error.message, "error");
        
        setisloading(false);
      }
    }
    return;
  };

  export const currentDateTime = new Date().toISOString();