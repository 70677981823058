import CurrencyVsPriceComponent from "../../components/CurrencyVsPrice/CurrencyVsPrice.Component";
import { Link, NavLink, Outlet, useLocation } from "react-router-dom";
import company_research from "../../Assets/company-research.jpg";
import getStarted from "../../Assets/get_started_side_2.jpg";
import { Table } from "antd";
import { TiChevronRightOutline } from "react-icons/ti";
const ServicesParentPage = () => {
  const location = useLocation();

  const dataSource = [
    {
      key: "1",
      Currency: "Us Dollar",
      Buy: 753.86,
      Central: 754.86,
      Sell: 753.86,
    },
    {
      key: "2",
      Currency: "Pounds Sterling",
      Buy: 947.9,
      Central: 947.63,
      Sell: 948.9,
    },
    {
      key: "3",
      Currency: "Euro",
      Buy: 808,
      Central: 809.89,
      Sell: 809.0,
    },
    {
      key: "4",
      Currency: "swiss franc",
      Buy: 841.42,
      Central: 802.76,
      Sell: 842.9,
    },
    {
      key: "5",
      Currency: "cfa",
      Buy: 1.29,
      Central: 1.63,
      Sell: 1.9,
    },
    {
      key: "6",
      Currency: "waua",
      Buy: 1037.98,
      Central: 1003.89,
      Sell: 1038.0,
    },
    {
      key: "7",
      Currency: "South africa rand",
      Buy: 39.86,
      Central: 39.86,
      Sell: 40.86,
    },
    {
      key: "8",
      Currency: "yuan/renminbi",
      Buy: 103.9,
      Central: 102.63,
      Sell: 102.09,
    },
    {
      key: "9",
      Currency: "Euro",
      Buy: 808,
      Central: 809.89,
      Sell: 809.0,
    },
  ];

  const columns = [
    {
      title: "Currency",
      dataIndex: "Currency",
      key: "Currency",
    },
    {
      title: "Buy",
      dataIndex: "Buy",
      key: "Buy",
    },
    {
      title: "Central",
      key: "Central",
      dataIndex: "Central",
    },
    {
      title: "Sell",
      dataIndex: "Sell",
      key: "Sell",
    },
  ];
  return (
    <div>
      <div
        className="bg-cover bg-[center] bg-no-repeat flex justify-center flex-col items-center h-[45vh] gap-10"
        style={{
            backgroundImage: `linear-gradient(rgba(26,26,26,0.3), rgba(26,26,26,0.9)), url(${company_research})`,
        //   backgroundImage: `url(${company_research})`,
        }}
      >
        <p className="  text-[41px]  text-white font-[400] flex_center flex-col capitalize">
          {location.pathname === "/Products/"
            ? "what we do"
            : location.pathname === "/Products/investment_advisory"
            ? "Investment Advisory"
            : location.pathname === "/Products/fixed_income_Investment"
            ? "fixed income Investment"
            : location.pathname === "/Products/Brokerage_Dealing"
            ? "Brokerage & Dealing"
            : null}
          <span className="border-[1px] w-[20%]"></span>
        </p>

        <ul className="flex_center text-white gap-3 font-[600] leading-loose">
          <li >
            <Link className="flex justify-center items-center gap-1" to="/">Home <TiChevronRightOutline /></Link>
          </li>
          <li >
            <Link className="flex justify-center items-center gap-1" to="/Products/">What we do <TiChevronRightOutline /></Link>
          </li>
          <li >
            <Link>
              {location.pathname === "/Products/"
                ? null
                : location.pathname === "/Products/investment_advisory"
                ? "Investment Advisory"
                : location.pathname === "/Products/fixed_income_Investment"
                ? "Fixed income Investment"
                : location.pathname === "/Products/Brokerage_Dealing"
                ? "Brokerage & dealing"
                : null}
            </Link>
          </li>
        </ul>
      </div>

      <div className="flex w-[85%] gap-8 pt-8 mx-auto lg:flex-row flex-col">
        <Outlet />
        <div className="flex flex-col  md:w-[35%]  w-full gap-5">
          <div className="">
            <p className="border flex flex-col  bg-[#f4f4f4] ">
              <NavLink
                to="/Products/"
                className={({ isActive }) =>
                  isActive
                    ? "bg-[#b49132] font-[600] border-b border-grey px-2  py-4 text-white"
                    : "border-b border-grey px-2  py-4 hover:text-[white] text-[16px] font-[400] text-[#373c5f] hover:bg-[#b49132]"
                }
              >
                What We Do
              </NavLink>
              <NavLink
                to="/Products/investment_advisory"
                className={({ isActive }) =>
                  isActive
                    ? "bg-[#b49132] font-[600] border-b border-grey px-2  py-4 text-white"
                    : "border-b border-grey px-2  py-4 hover:text-[white] text-[16px] font-[400] text-[#373c5f] hover:bg-[#b49132]"
                }
              >
                Investment Advisory
              </NavLink>
              <NavLink
                to="/Products/fixed_income_Investment"
                className={({ isActive }) =>
                  isActive
                    ? "bg-[#b49132] font-[600] border-b border-grey px-2  py-4 text-white"
                    : "border-b border-grey px-2  py-4 hover:text-[white] text-[16px] font-[400] text-[#373c5f] hover:bg-[#b49132]"
                }
              >
                Flxed Income Advisory
              </NavLink>
              <NavLink
                to="/Products/Brokerage_Dealing"
                className={({ isActive }) =>
                  isActive
                    ? "bg-[#b49132] font-[600] border-b border-grey px-2  py-4 text-white"
                    : "border-b border-grey px-2  py-4 hover:text-[white] text-[16px] font-[400] text-[#373c5f] hover:bg-[#b49132]"
                }
              >
                Brokerage and Dealing
              </NavLink>
            </p>
          </div>
          <div className="dave flex gap-1 flex-col">
            <p className="text-[#b49132] text-2xl">Forex - CBN Rates</p>
            <Table
              dataSource={dataSource}
              columns={columns}
              pagination={false}
              size="small"
              className="b"
            />
            <p>
              Source : CBN.{" "}
              <span className="text-[#b49132] pb-2 leading-loose decoration-[#b49132] decoration-dotted underline">
                View More
              </span>
            </p>
          </div>

          <CurrencyVsPriceComponent />
          <div
            className=" w-[full]  text-white  flex justify-center items-center my-8 bg-center bg-cover"
            style={{
              backgroundImage: `url(${getStarted})`,
            }}
          >
            <div className="flex justify-between items-center w-[85%] mx-auto py-12 flex-col gap-5 text-center ">
              <p className="flex flex-col text-[21px] leading-[1.8] capitalize">
                manage your Investment with more confidence.{" "}
                <span className="text-[18px]">
                  Open a trading Account today and kickstart your investments
                </span>
              </p>
              <button className="rounded px-8 bg-[#b49132]  py-2 text-[16px] uppercase leading-loose hover:bg-[#313140] hover:text-white ease-in-out duration-[0.5s]">
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesParentPage;
