import { Image } from "antd";
import React from "react";
import ShowMoreText from "react-show-more-text";

const BoardCard = ({ boardDetails }) => {
  return (
    <div className="flex md:justify-start md:items-start flex-col md:flex-row gap-5 my-10 py-2 w-full border-b">
      <div className="flex flex-col justify-center items-center gap-10 md:w-[30%] md:border-r ">
        <div className=" w-[200px] h-[200px] ">
          <Image
            src={boardDetails?.imgUrl}
            alt=""
            className=" w-full h-full rounded-[50%] object-cover"
          />
        </div>
        <p className=" capitalize text-sm font-bold justify-center items-center gap-10 w-full text-center">
          {boardDetails?.title} <span className="text-lg font-bold">{boardDetails?.name}</span>
        </p>
      </div>

      <div className="md:w-[70%] ">
        <p className="text-justify text-[16px] text-[grey] ">
        <ShowMoreText
                /* Default options */
                lines={7}
                more="Show more"
                less="Show less"
                className="content-css"
                anchorClass="show-more-less-clickable"
                // onClick={this.executeOnClick}
                expanded={false}
                // width={280}
                truncatedEndingComponent={"... "}
            >

           
          {boardDetails.description}
          </ShowMoreText>
        </p>
      </div>
    </div>
  );
};

export default BoardCard;
