import { Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import adminServices from "../../services/httpServices";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";

const Toplosers = () => {
  const [searchParam, setSerachParams] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [allTableData, setallTableData] = useState([]);
  const [topGainer, setTopGainer] = useState([]);
  const [TopLoser, setTopLoser] = useState([]);
  const [isloading, setIsloading] = useState(false);

  const fetch = async () => {
    setIsloading(true);
    try {
      const res = await adminServices.GetAllStocks();
      const mappedData = res?.data.map((value) => {
        return {
          ...value,
          key: value.id,
        };
      });
      setallTableData(mappedData);

      const topLoserRes = res?.data
        .filter((value) => value.change && value.percentageChange < 0)
        .filter((item, index, self) => 
          index === self.findIndex((t) => (t.symbol === item.symbol))
        )
        .map((value) => {
          return { ...value, key: value.id };
        });
      const TopGainerRes = res?.data
        .filter((value) => value.change && value.percentageChange > 0)
        .map((value) => {
          return { ...value, key: value.id };
        });
      setTopLoser(topLoserRes);
      setTopGainer(TopGainerRes);
      setIsloading(false);
    } catch (error) {
      setallTableData([]);
      setTopLoser([]);
      setTopGainer([]);
      setIsloading(false);
    //console.log(error);
    }
  };

  const GainerColumns2 = [
    {
      title: "Company",
      dataIndex: "symbol" ,
      width: 100,
      key: "symbol",
    },
    {
      title: "PCLOSE",
      dataIndex: "pClose",
      key: "pClose",
    },

    {
      title: "CLOSE",
      key: "close",
      dataIndex: "close",
    },
    {
      title: <p className=" text-center">CHG </p>,
      dataIndex: "percentageChange",
      key: "percentageChange",
      render: (text) => (
        <div className=" w-full flex justify-center items-center">
        <Tag color={`${text === 0 ? "blue" : text < 0 ? "red" : "green"}`}   className=" lg:min-w-[60%] lg:mx-auto text-center" >
          {text < 0 ? <CaretDownOutlined /> : <CaretUpOutlined />}
          {text?.toLocaleString()}
        </Tag>
      </div>
      ),
    },
    
  ];

  useEffect(() => {
    fetch();
  }, []);


  const OnchangeHandler = (e) => {
    e.preventDefault();
    const searchText = e.target.value;
    setSerachParams(searchText);

    const theFilterData = TopLoser.filter((value, _) =>
      Object.values(value)
        .join("")
        .toLowerCase()
        .includes(searchText.toLowerCase())
    );

    setFilteredData(theFilterData);
  };
  const RealdataSource = searchParam ? filteredData : TopLoser;
  return (
    <div className="w-full text-[18px] text-justify leading-loose text-[#424242]">
      <div className="flex flex-col gap-5 ">
        <p className="flex justify-center items-center md:items-start  flex-col  lg:text-[30px] text-[24px] leading-loose  font-bold text-[#373c5f] pb-3 text-center">
        Today's Losers for Equity
          <span className="border-[1px] border-[#b49132] w-[40%] md:w-[5%]"></span>
        </p>
        <p>
        See the table for the latest losers for equity in the Nigerian Equities Market.
        </p>
        <p>
          *** As released by the Nigerian Stock Exchange (NSE) on{" "}
          <span className="text-[#b49132] underline decoration-dotted text-sm">
            2nd September, 2021
          </span>
        </p>
      </div>
      <div className="flex flex-col gap-5">
        <input
          type="search"
          className="border px-2 
         border-[#424242] outline-none w-[40%] flex_center"
          placeholder="search...."
          onChange={(e) => OnchangeHandler(e)}
        />
        <Table
          dataSource={RealdataSource}
          columns={GainerColumns2}
          // size="small"
          className="dave2 w-full "
          scroll={{ x: 400 }}
          loading={isloading}
        />
       <div className="text-sm border bg-[#e4e4e4] w-full lg:flex  gap-2 py-3 px-1 font-[500] leading-loose">
                <p className="font-bold text-[#b49132]">LEGEND : </p>
                <p className="">
                  {" "}
                  PCLOSE - Previous Close Price; <span> </span> CHG - Change;
                  RED - Loss; GREEN - Gain;
                  BLUE - No Change
                </p>
              </div>
      </div>
    </div>
  );
};

export default Toplosers;
