import firstIcon from "../../Assets/Icons/1.png";
import ThirdIcon from "../../Assets/Icons/3.png";
import secondIcon from "../../Assets/Icons/2.png";
import { AiOutlineArrowRight } from "react-icons/ai";
import logo from "../../Assets/Heritage_logo.png";
import text from "../../Assets/Icons/13.png";
import abt_us_Img from "../../Assets/abt_us.jpg";
import Call_banner from "../../Assets/call-baner1.jpg";
import { Link } from "react-router-dom";
import { FaAngleDoubleRight, FaPlay } from "react-icons/fa";
import { FaNairaSign } from "react-icons/fa6";
import slide from '../../Assets/slides/slide-6.jpg'
import { BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";
import {
  Badge,
  Carousel,
  DatePicker,
  Image,
  Result,
  Spin,
  Table,
  Tag,
} from "antd";
import "./homepage.css";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import adminServices from "../../services/httpServices";
import NotificationService from "../../NotificationService";
import ImageSlider from "../../components/ImageSlider/ImageSlider";
import LatestReport from "../../components/latest-report";
import BusinessNews from "../../components/businessNews";

const Homepage = () => {
  const [sliderDetails, setSliderDetails] = useState(undefined);
  const [isloading, setisloading] = useState("");
  const [error, setError] = useState(false);
  const [latestReports, setLatestReports] = useState([]);
  const [businessNews, setBusinessNews] = useState([]);
  const [topGainer, setTopGainer] = useState([]);
  const [TopLoser, setTopLoser] = useState([]);

  const fetch = async () => {
    setisloading(true);
    try {
      const res = await adminServices.GetAllStocks();
      //console.log(res);
      const mappedData = res?.data?.map((value) => {
        return {
          ...value,
          key: value.id,
        };
      });
      const topLoserRes = res?.data
        .slice(0, 5)
        .filter((value) => value.change && value.percentageChange < 0)
        .map((value) => {
          return { ...value, key: value.id };
        });
      const TopGainerRes = res?.data
        .slice(0, 5)
        .filter((value) => value.change && value.percentageChange >= 0)
        .map((value) => {
          return { ...value, key: value.id };
        });
      setTopLoser(topLoserRes);
      setTopGainer(TopGainerRes);
      setisloading(false);
    } catch (error) {
      NotificationService.show(error.message, "error");
      // setallTableData([]);
      setTopLoser([]);
      setTopGainer([]);
      setisloading(false);
      //console.log(error);
    }
  };

  const fetchPost = async () => {
    setisloading(true);
    try {
      const res = await adminServices.getAllPost();
      const mappedTableData = res?.data
        ?.filter((value) => value.isactive === true)
        .filter((value) => value.categoryName === "Latest News")
        .map((value, index) => {
          return { ...value, key: index };
        });
      const mappedBusinessData = res?.data
        ?.filter((value) => value.isactive === true)
        .filter((value) => value.categoryName === "Business News")
        .map((value, index) => {
          return { ...value, key: index };
        });
      setBusinessNews(mappedBusinessData);
      setLatestReports(mappedTableData);
      setisloading(false);
    } catch (error) {
      //console.log(error);
      setisloading(false);

      NotificationService.show(error.message, "error");
    }
  };

  const GainerColumns2 = [
    {
      title: "Company",
      dataIndex: "symbol" ,
      width: 100,
      key: "symbol",
    },
    {
      title: "PCLOSE",
      dataIndex: "pClose",
      key: "pClose",
    },

    {
      title: "CLOSE",
      key: "close",
      dataIndex: "close",
    },
    {
      title: "CHG",
      dataIndex: "percentageChange",
      key: "percentageChange",
      render: (text) => (
        <Tag color={`${text < 0 ? "red" : "green"}`}>
          {text < 0 ? <CaretDownOutlined /> : <CaretUpOutlined />}
          {text}
        </Tag>
      ),
    },
    {
      title: "Trade Date",
      key: "tradeDate",
      dataIndex: "tradeDate",
      width: 120,
      render: (text) => <p> {text?.split("T")[0]}</p>,
    },
  ];



  const getSlider = async () => {
    setError(false);
    setisloading(true);
    try {
      const res = await adminServices.getAllSlider();

      const FilteredRes = res?.data?.filter((value) => value.isactive === true);
      setSliderDetails(FilteredRes);

      setError(false);
      setisloading(false);
    } catch (error) {
      setisloading(true);

      setError(true);
      NotificationService.show(error.message, "error");
    }
  };

  useEffect(() => {
    fetch();
    fetchPost();
    getSlider();
  }, []);
  return (
    <div>
      <div className="md:relative md:pb-[250px] ">
        {isloading && !error ? (
          <div className="md:h-[90vh] h-[90vh]  md:mt-[-50px] ">
            <div className=" h-full">
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 74 }} />}
                className="animate-ping   flex justify-center items-center h-full"
              />
            </div>
          </div>
        ) : error ? (
          <div className="md:h-[90vh] h-[90vh]   md:mt-[-50px]  flex justify-center items-center ">
            <Result
              status="500"
              title="Refresh"
              subTitle="Sorry, something went wrong."
              // extra={<Button type="primary">Re</Button>}
            />
          </div>
        ) : !sliderDetails ? (
          <div className="">
             <div className="md:h-screen h-[90vh]  ">
            <div
              className="capitalize h-full  flex justify-center  md:justify-start pt-[10%] items-center bg-center bg-cover bg-no-repeat flex-col text-white  "
              style={{ backgroundImage: `url(${slide})` }}
            >
              <div className="w-[95%] mx-auto  flex flex-col justify-center items-center text-center md:gap-2 gap-5 ">
                <p className="lg:text-[42px] text-[20px] capitalize lg:flex flex-col justify-center items-center leading-tight  ">
                Your Growth
                 
                </p>
                <p className="text-[24px] break-all lg:text-[39px] font-bold uppercase "> OUR BUSINESS</p>{" "}
                <p className="text-[20px] text-center md:flex flex-col justify-center items-center font-[200] w-[90%] md:w-[70%] ">
                
                 With Over 20 Years Of Experience, We'll Ensure\nYour Business Grow Exponentially
                </p>
                <Link to={'/AboutUs/'} >
                <button className="bg-[#B49132] hover:bg-[#9b7c27] rounded-lg uppercase py-2 px-5 font-bold mt-8 text-[16px]">
                  Learn More
                </button>
                </Link>
              </div>
            </div>
          </div>
          </div>
        ) : (
          <Carousel autoplay={true} dotPosition="right" className="!h-[100vh]">
            {sliderDetails &&
              sliderDetails?.map((value, index) => (
                <ImageSlider slidesDetails={value} key={index} />
              ))}
          </Carousel>
        )}
        {/* second section  */}

        <div className="bottom-0 left-[5%] lg:absolute   mb-2 md:rounded-lg grid lg:grid-cols-3 grid-cols-1 w-[90%] mx-auto justify-center items-start gap-5  md:gap-8 bg-[#1F2134] pt-8 md:pt-0">
          <div
            className="gap-3 flex justify-center items-center flex-col  md:p-10 px-10 py-2 "
            data-aos="zoom-in-up"
            data-aos-easing="linear"
            data-aos-duration="600"
          >
            <div className="border-2 border-[#b49132] p-2">
              <img src={firstIcon} alt="" />
            </div>
            <div className="flex flex-col justify-center items-center gap-5">
              <h2 className="text-white text-2xl ">Financial Planning</h2>
              <p className="text-[darkgrey] lg:text-center leading-tight ">
                Whether you want to invest on your own, have a windfall you want
                to invest or a financial challenge you’d like to solve, we would
                like to hear from you.
              </p>
              <Link
                to={"/Products/financial-Investments"}
                className="text-[#b49132] font-[600] flex justify-center items-center gap-3"
              >
                Read more <AiOutlineArrowRight />
              </Link>
            </div>
          </div>

          <div
            className="gap-3 flex justify-center items-center flex-col  md:p-10 px-10 py-2   lg:border-l-[1px] h-[85%] my-auto"
            data-aos="zoom-in-up"
            data-aos-easing="linear"
            data-aos-duration="600"
          >
            <div className="border-2 border-[#b49132] p-2">
              <img src={secondIcon} alt="" />
            </div>
            <div className="flex flex-col justify-center items-center gap-5">
              <h2 className="text-white text-2xl ">Portfolio Management</h2>
              <p className="text-[darkgrey] text-center leading-tight">
                Managing investments can take all of your time. At Heritage
                Capitals, we help you manage your portfolio of investments as
                they increase in type and in volume.
              </p>
              <Link
                to={"/Products/financial-Investments"}
                className="text-[#b49132] font-[600] flex justify-center items-center gap-3"
              >
                Read more <AiOutlineArrowRight />
              </Link>
            </div>
          </div>

          <div
            className="gap-3 flex justify-center items-center flex-col  md:p-10 px-10 py-2  lg:border-l-[1px] h-[85%] my-auto"
            data-aos="zoom-in-up"
            data-aos-easing="linear"
            data-aos-duration="600"
          >
            <div className="border-2 border-[#b49132] p-2">
              <img src={ThirdIcon} alt="" />
            </div>
            <div className="flex flex-col justify-center items-center gap-5">
              <h2 className="text-white text-2xl ">Alternative Investment</h2>
              <p className="text-[darkgrey] text-center leading-tight">
                At Heritage Capitals, we are poised at helping you improve
                returns and control risk by investing a small proportion of your
                portfolio in asset classes.
              </p>
              <Link
                to={"/Products/fixed_income_Investment"}
                className="text-[#b49132] font-[600] flex justify-center items-center gap-3"
              >
                Read more <AiOutlineArrowRight />
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* third section  */}
      <div
        data-aos="zoom-in-up"
        data-aos-easing="linear"
        data-aos-duration="600"
      >
        <div
          className="md:w-[80%] w-[85%] mx-auto flex justify-center items-center gap-8 md:p-10 p-4 flex-col bg-center bg-cover  bg-no-repeat"
          style={{
            backgroundImage: `linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(${abt_us_Img})`,
          }}
        >
          <div className="flex justify-center items-center flex-col  uppercase leading-tight">
            <h2 className="md:text-[36px] text-[24px] text-center gap-1 md:gap-0 w-full font-bold text-[#373c5f]">
              Welcome to Heritage Capital Markets Limited
            </h2>
            <span className="border-[#b49132] border-[1px] md:w-[4%] w-[15%]"></span>
          </div>
          <p className="text-[18px] text-[#424242] text-center ">
            At Heritage Capital Markets Limited, you can enjoy a personal
            relationship with our investment professionals. We see investing
            only from your perspective and make recommendations based on
            actually listening to you and understanding your needs. We won’t
            push unsuitable securities and investments to you because we realize
            that your goals and needs are completely unique.
          </p>
          <Link
            to={"/AboutUs/"}
            className="text-[#b49132] font-[600] flex justify-center items-center gap-3"
          >
            <button className="bg-[#B49132] hover:bg-[#9b7c27] text-white rounded-lg capitalize flex justify-center items-center gap-2 py-2 px-5 font-bold text-[16px]">
              Read more{" "}
              <span>
                <AiOutlineArrowRight />
              </span>
            </button>
          </Link>
        </div>
      </div>
      {/* fouth section  */}
      <div
        data-aos="zoom-in-up"
        data-aos-easing="linear"
        data-aos-duration="600"
        className="flex flex-col justify-center items-center md:px-10 h-[400px] bg-center bg-cover bg-scroll bg-black "
        style={{
          backgroundImage: `linear-gradient(rgba(26,26,26,0.8), rgba(26,26,26,0.8)), url(${Call_banner})`,
        }}
      >
        <p className="md:text-[42px] text-[36px] pb-3 md:pb-0 w-full text-white text-center">
          Manage your investments with more{" "}
          <span className="text-[#B49132] font-[600]">confidence</span> .
        </p>
        <p className="text-[18px] text-white text-center">
          Open a trading account and kickstart your investments.
        </p>
        <Link to={"/IndividualAccount"}>
          <button className="bg-[#B49132] hover:bg-[#9b7c27] my-5 text-white rounded-lg capitalize py-2 px-5 font-bold text-[16px] ">
            Open Account
          </button>
        </Link>
      </div>

      {/* fifth section  */}
      {isloading ? (
        <Spin
          indicator={<LoadingOutlined style={{ fontSize: 44 }} />}
          className="animate-ping   flex justify-center items-center h-[50%]"
        />
      ) : (
        <div
          className="flex lg:flex-row flex-col justify-between items-start lg:w-[85%] w-[90%] mx-auto gap-5 my-10 "
          id="Business"
        >
          <div
            className=" flex flex-col justify-start items-start lg:w-[50%] w-full gap-5  "
            data-aos="zoom-in-up"
            data-aos-easing="linear"
            data-aos-duration="600"
          >
            <p className="text-[35px] text-[#1F2134]">Latest Reports</p>
            {latestReports.length === 0 ? (
              <div className="h-[100%] justify-center items-center flex ">
                <p className="text-center capitalize text-lg font-bold ">
                  No Report
                </p>
              </div>
            ) : (
              // latestReports &&
              // latestReports?.map((value, index) => (
              <LatestReport
                key={2}
                latestReports={latestReports[latestReports.length - 1]}
              />
              // ))
            )}
          </div>

          <div
            className=" flex flex-col justify-center items-start  lg:w-[50%] w-full gap-5 "
            data-aos="zoom-in-up"
            data-aos-easing="linear"
            data-aos-duration="600"
          >
            <p className="text-[35px] ">Business News</p>
            <div className="w-full">
              {businessNews.length === 0 ? (
                <div className="h-[100%] justify-center items-center flex ">
                  <p className="text-center capitalize text-lg font-bold ">
                    No News
                  </p>
                </div>
              ) : (
                <Carousel autoplay={true} className="w-full  text-white ">
                  {businessNews &&
                    businessNews?.map((value, index) => (
                      <BusinessNews key={index} businessNews={value} />
                    ))}
                </Carousel>
              )}
            </div>
          </div>
        </div>
      )}

      {/* SIXTH SECTION  */}
      <div
        className="bg-[#b49132] flex flex-col justify-center items-center gap-10 py-20"
        data-aos="zoom-in-up"
        data-aos-easing="linear"
        data-aos-duration="600"
      >
        <p className="flex flex-col justify-center items-center  gap-2 text-white text-[41px] font-[sans-serif] font-[100]">
          Stock Watch{" "}
          <span className="border-[1px] border-white w-[15%]"></span>
        </p>
        <div className="flex flex-wrap gap-5 justify-evenly items-start md:w-[85%] mx-auto">
          <div
            className="flex flex-col md:justify-start md:items-start justify-center items-center gap-1 "
            data-aos="zoom-in-up"
            data-aos-easing="linear"
            data-aos-duration="600"
          >
            <p className="flex justify-center items-center gap-1 text-white">
              {" "}
              <span>
                <FaAngleDoubleRight />
              </span>{" "}
              Top 5 Gainers - 2nd Sep, 2021
            </p>
            <Table
              dataSource={topGainer}
              columns={GainerColumns2}
              pagination={false}
              size="small"
            />
            <Link to={"/Research/top_gainers"}>
              <button className="bg-none border-black border-2 hover:bg-[#9b7c27] my-2 text-white rounded-lg capitalize py-2 px-3 font-bold text-sm ">
                See all Gainers
              </button>
            </Link>
          </div>

          <div
            className="flex flex-col md:justify-start md:items-start justify-center items-center gap-1 "
            data-aos="fade-up-right"
          >
            <p className="flex justify-center items-center gap-1 text-white">
              {" "}
              <span>
                <FaAngleDoubleRight />
              </span>{" "}
              Top 5 Losers - 2nd Sep, 2021
            </p>

            <Table
              dataSource={TopLoser}
              columns={GainerColumns2}
              pagination={false}
              size="small"
            />
            <Link to={"/Research/top_losers"}>
              <button className="bg-none border-black border-2 hover:bg-[#9b7c27] my-2 text-white rounded-lg capitalize py-2 px-3 font-bold text-sm ">
                See all Losers
              </button>
            </Link>
          </div>

          {/* <div
            className="flex flex-col md:justify-start md:items-start justify-center items-center gap-1 "
            data-aos="zoom-in-up"
            data-aos-easing="linear"
            data-aos-duration="600"
          >
            <p className="text-white">Stock Research</p>

            <div className=" bg-[#1F2134] p-2 pb-0 rounded-md border w-[85%] md:w-full mx-auto">
              <form
                action=""
                className=" flex flex-col justify-center items-center "
              >
                <div>
                  <label className="text-white font-[500] text-sm" htmlFor="">
                    Select Stock
                  </label>
                  <select className="w-full outline-none rounded-lg border-[darkgrey] border-b-2 pb-1 bg-transparent text-[darkgrey]">
                    <option value="">Berger</option>
                    <option value="">Portfolio Management</option>
                    <option value="">Financial Planning</option>
                    <option value="">Fixed Income Investment</option>
                    <option value="">Berger</option>
                    <option value="">
                      Stock Broking / Brokerage & Dealing
                    </option>
                    <option value="">Alternative Investment</option>
                    <option value="">Investment advisory</option>
                    <option value="">Portfolio Management</option>
                    <option value="">Financial Planning</option>
                    <option value="">Financial Planning</option>
                    <option value="">Fixed Income Investment</option>
                    <option value="">Berger</option>
                    <option value="">
                      Stock Broking / Brokerage & Dealing
                    </option>
                  </select>
                </div>
                <div className="flex flex-col justify-start items-start  py-2 w-full">
                  <label className="text-white font-[500] text-sm" htmlFor="">
                    Start Date
                  </label>
                  <DatePicker className="outline-none border-0 border-b-2 bg-transparent w-full" />
                </div>

                <div className="flex flex-col justify-start items-start   w-full">
                  <label className="text-white font-[500] text-sm" htmlFor="">
                    End Date
                  </label>
                  <DatePicker className="outline-none border-0 border-b-2 bg-transparent w-full" />
                </div>
              </form>
              <Link to={"/Research/"}>
                <button className="bg-[#b49132] w-[60%] ml-[40%] hover:bg-[#9b7c27] my-2 text-white rounded-lg capitalize py-2 px-2  text-[16px] ">
                  <span>
                    <SearchOutlined />
                  </span>{" "}
                  View Price History
                </button>
              </Link>
            </div>
          </div> */}
        </div>
      </div>

      {/* last section  */}
      <div
        className="flex justify-center items-center flex-col  w-[85%] mx-auto gap-2 py-20 font-[300] "
        data-aos="zoom-in-up"
        data-aos-easing="linear"
        data-aos-duration="600"
      >
        <div
          className="lg:w-[80%] w-[90%] flex justify-center items-center flex-col gap-10  text-justify"
          data-aos="zoom-in-up"
          data-aos-easing="linear"
          data-aos-duration="600"
        >
          <p className="flex justify-center items-center flex-col lg:text-[41px] text-[27px] text-[#373c5f]">
            REQUEST A CALL BACK{" "}
            <span className="border-[1px] border-[#B49132] w-[10%]"></span>
          </p>
          <p>
            Are you interested in increasing your investment portfolio? Are you
            confused about how and where to start your capital investment? Do
            you want to diversify your investments across countries or are you
            just interested in improving your investment returns? Simply provide
            your details and a professional will call you back!
          </p>
        </div>

        {/* <div className="w-full "   data-aos='zoom-in-up'
data-aos-easing="linear"
     data-aos-duration="600"> */}
        {/* <form
            action=""
            className="flex justify-center items-center flex-col text-[#868686] gap-10 w-full mb-5"
          >
            <select>
              <option value="">Select Product.....</option>
              <option value="">Portfolio Management</option>
              <option value="">Financial Planning</option>
              <option value="">Fixed Income Investment</option>
              <option value="">Stock Broking / Brokerage & Dealing</option>
              <option value="">Alternative Investment</option>
              <option value="">Investment advisory</option>
            </select>
            <div className=" w-full grid grid-cols-2 justify-center items-center gap-10">
              <input
                type="text"
                placeholder="First Name"
                className="w-full outline-none border-b-2"
                required
              />
              <input
                type="text"
                placeholder="Last Name"
                className="w-full outline-none border-b-2"
                required
              />
              <input
                type="email"
                placeholder="Email Address"
                className="w-full outline-none border-b-2"
                required
              />
              <input
                type="number"
                placeholder="Phone Number"
                className="w-full outline-none border-b-2"
                required
              />
            </div>
          </form> */}
        <Link to={"/Contact"}>
          <Badge.Ribbon text="contact" color="#1F2134">
            <button className="bg-[#B49132] hover:bg-[#9b7c27] my-5  text-white rounded-lg uppercase py-3 px-5 font-bold text-[16px] ">
              Contact Us
            </button>
          </Badge.Ribbon>
        </Link>
        {/* </div> */}
      </div>

      {/* marquee  */}
    </div>
  );
};

export default Homepage;
