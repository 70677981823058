import { Collapse, Dropdown, Space } from "antd";
import logo from "../../Assets/Heritage_logo.png";
import { DownOutlined } from "@ant-design/icons";
import { IoIosMenu } from "react-icons/io";
import { HiPlusSm } from "react-icons/hi";
import { GrClose } from "react-icons/gr";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "./navbar.css";
import { useState } from "react";
import adminServices from "../../services/httpServices";
import { TiChevronRightOutline } from "react-icons/ti";
import { useEffect } from "react";
import NotificationService from "../../NotificationService";
import RegistrationModal from "../../components/formModal";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [formTypeData, setFormTypeData] = useState([]);
  const [ismodalOpen, setIsModalOpen] = useState(false);
  const [formToDisplay, setformToDisplay] = useState("");
  const navigate = useNavigate();

  const fetch = async () => {
    try {
      const res = await adminServices.getAllFormType();
      const mappedRes = res?.data.map((value) => {
        return {
          key: value.id,
          label: (
            <NavLink
              className={({ isActive }) =>
                isActive ? "ActiveLink " : "InActiveLink"
              }
              to={`/${value.formName.replace(/\s/g, "")}`}
            >
              {value.formName}
            </NavLink>
          ),
        };
      });
    //console.log(mappedRes);
      setFormTypeData(mappedRes);
    } catch (error) {
      // console.log(error);
      NotificationService.show(error.message, "error");
    }
  };

  const items = [
    {
      key: "1",
      label: (
        <NavLink
          className={({ isActive }) =>
            isActive ? "ActiveLink " : "InActiveLink"
          }
          to="AboutUs/"
        >
          Who We Are
        </NavLink>
      ),
    },
    {
      key: "2",
      label: (
        <NavLink
          className={({ isActive }) =>
            isActive ? "ActiveLink " : "InActiveLink"
          }
          to="AboutUs/OurCompany"
        >
          Our Company
        </NavLink>
      ),
    },

    {
      key: "4",
      label: (
        <NavLink
          className={({ isActive }) =>
            isActive ? "ActiveLink " : "InActiveLink"
          }
          to="AboutUs/management_team"
        >
          Management Team
        </NavLink>
      ),
    },
    {
      key: "5",
      label: (
        <NavLink
          className={({ isActive }) =>
            isActive ? "ActiveLink " : "InActiveLink"
          }
          to="AboutUs/Boards"
        >
          Board of directors
        </NavLink>
      ),
    },
  ];

  const items2 = [
    {
      key: "1",
      label: "About",
      children: (
        <ul className="flex flex-col gap-4">
          <li>
            <NavLink
              onClick={() => setIsOpen(!isOpen)}
              className={({ isActive }) =>
                isActive ? "ActiveLinkSmallScreen" : "InActiveLink"
              }
              to="AboutUs/"
            >
              Who We Are
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => setIsOpen(!isOpen)}
              className={({ isActive }) =>
                isActive ? "ActiveLinkSmallScreen" : "InActiveLink"
              }
              to="AboutUs/OurCompany"
            >
              Our Company
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => setIsOpen(!isOpen)}
              className={({ isActive }) =>
                isActive ? "ActiveLinkSmallScreen" : "InActiveLink"
              }
              to="AboutUs/management_team"
            >
              Management Team
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => setIsOpen(!isOpen)}
              className={({ isActive }) =>
                isActive ? "ActiveLinkSmallScreen" : "InActiveLink"
              }
              to="AboutUs/Boards"
            >
              Board of directors
            </NavLink>
          </li>
        </ul>
      ),
    },
    {
      key: "2",
      label: "Product & Services",
      children: (
        <ul className="flex flex-col gap-4">
          <li>
            <NavLink
              onClick={() => setIsOpen(!isOpen)}
              className={({ isActive }) =>
                isActive ? "ActiveLinkSmallScreen" : "InActiveLink"
              }
              to="Products/"
            >
              Our Products
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => setIsOpen(!isOpen)}
              className={({ isActive }) =>
                isActive ? "ActiveLinkSmallScreen" : "InActiveLink"
              }
              to="/Products/Brokerage_Dealing"
            >
              Brokerage & Dealing
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => setIsOpen(!isOpen)}
              className={({ isActive }) =>
                isActive ? "ActiveLinkSmallScreen" : "InActiveLink"
              }
              to="/Products/investment_advisory"
            >
              Investment Advisory
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => setIsOpen(!isOpen)}
              className={({ isActive }) =>
                isActive ? "ActiveLinkSmallScreen" : "InActiveLink"
              }
              to="Products/financial-Investments"
            >
              Financial Planning
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => setIsOpen(!isOpen)}
              className={({ isActive }) =>
                isActive ? "ActiveLinkSmallScreen" : "InActiveLink"
              }
              to="/Products/fixed_income_Investment"
            >
              Fixed Income Investment
            </NavLink>
          </li>
          {/* <li>
            <NavLink
              onClick={() => setIsOpen(!isOpen)}
              className={({ isActive }) =>
                isActive ? "ActiveLinkSmallScreen" : "InActiveLink"
              }
              to="Products/Stoking-broking"
            >
              Stockbroking&Dealing
            </NavLink>
          </li> */}
        </ul>
      ),
    },
    {
      key: "3",
      label: "Research",
      children: (
        <ul className="flex flex-col gap-4">
          <li>
            <NavLink
              onClick={() => setIsOpen(!isOpen)}
              className={({ isActive }) =>
                isActive ? "ActiveLinkSmallScreen" : "InActiveLink"
              }
              to="/Research/"
            >
              Current Price List
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => setIsOpen(!isOpen)}
              className={({ isActive }) =>
                isActive ? "ActiveLinkSmallScreen" : "InActiveLink"
              }
              to="/Research/top_gainers"
            >
              Top Gainers
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => setIsOpen(!isOpen)}
              className={({ isActive }) =>
                isActive ? "ActiveLinkSmallScreen" : "InActiveLink"
              }
              to="/Research/top_losers"
            >
              Top Losers
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => setIsOpen(!isOpen)}
              className={({ isActive }) =>
                isActive ? "ActiveLinkSmallScreen" : "InActiveLink"
              }
              to="/Research/Company_Research"
            >
              Our Research
            </NavLink>
          </li>
          <li>
            {/* <NavLink
        onClick={() => setIsOpen(!isOpen)}
            className={({ isActive }) =>
              isActive ? "ActiveLinkSmallScreen" : "InActiveLink"
            }
            to="/all_news/">Market Briefs
          </NavLink> */}
          </li>
          {/* <li>
            <NavLink
              onClick={() => setIsOpen(!isOpen)}
              className={({ isActive }) =>
                isActive ? "ActiveLinkSmallScreen" : "InActiveLink"
              }
              to="Products/Stoking-broking"
            >
              Stockbroking
            </NavLink>
          </li> */}
        </ul>
      ),
    },
    {
      key: "4",
      label: "Registration (online)",
      children: (
        <ul className="flex flex-col gap-4">
          <li>
            <NavLink
              onClick={() => setIsOpen(!isOpen)}
              className={({ isActive }) =>
                isActive ? "ActiveLinkSmallScreen" : "InActiveLink"
              }
              to="IndividualAccount"
              // className="!text-white font-[500] text-[16px] hover:!text-[#9d9d9d]"
            >
              Individual Account
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => setIsOpen(!isOpen)}
              className={({ isActive }) =>
                isActive ? "ActiveLinkSmallScreen" : "InActiveLink"
              }
              to="CorporateAccount"
              // className="!text-white f/ont-[500] text-[16px] hover:!text-[#9d9d9d]"
            >
              Corporate Account
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => setIsOpen(!isOpen)}
              className={({ isActive }) =>
                isActive ? "ActiveLinkSmallScreen" : "InActiveLink"
              }
              to="Joint"
              // className="!text-white font-[500] text-[16px] hover:!text-[#9d9d9d]"
            >
              Joint Account
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => setIsOpen(!isOpen)}
              className={({ isActive }) =>
                isActive ? "ActiveLinkSmallScreen" : "InActiveLink"
              }
              to="Minor"
              // className="!text-white font-[500] text-[16px] hover:!text-[#9d9d9d]"
            >
              Minor Account
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => setIsOpen(!isOpen)}
              className={({ isActive }) =>
                isActive ? "ActiveLinkSmallScreen" : "InActiveLink"
              }
              to="EstateAccount"
              // className="!text-white font-[500] text-[16px] hover:!text-[#9d9d9d]"
            >
              Estate Account
            </NavLink>
          </li>
        </ul>
      ),
    },
    {
      key: "5",
      label: "Download (offline Registration)",
      children: (
        <ul className="flex flex-col gap-4">
          <li>
            <NavLink
              onClick={() => setIsOpen(!isOpen)}
              className={({ isActive }) =>
                isActive ? "ActiveLinkSmallScreen" : "InActiveLink"
              }
              to={"Individual_offline"}
              // className="!text-white font-[500] text-[16px] hover:!text-[#9d9d9d]"
            >
              Individual Account
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => setIsOpen(!isOpen)}
              className={({ isActive }) =>
                isActive ? "ActiveLinkSmallScreen" : "InActiveLink"
              }
              to={"Corporate_offline"}
              // className="!text-white f/ont-[500] text-[16px] hover:!text-[#9d9d9d]"
            >
              Corporate Account
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => setIsOpen(!isOpen)}
              className={({ isActive }) =>
                isActive ? "ActiveLinkSmallScreen" : "InActiveLink"
              }
              to={"Joint_offline"}
              // className="!text-white font-[500] text-[16px] hover:!text-[#9d9d9d]"
            >
              Joint Account
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => setIsOpen(!isOpen)}
              className={({ isActive }) =>
                isActive ? "ActiveLinkSmallScreen" : "InActiveLink"
              }
              to={"Minor_offline"}
              // className="!text-white font-[500] text-[16px] hover:!text-[#9d9d9d]"
            >
              Minor Account
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => setIsOpen(!isOpen)}
              className={({ isActive }) =>
                isActive ? "ActiveLinkSmallScreen" : "InActiveLink"
              }
              to={"Estate_offline"}

              // className="!text-white font-[500] text-[16px] hover:!text-[#9d9d9d]"
            >
              Estate Account
            </NavLink>
          </li>
        </ul>
      ),
    },
  ];

  const offlineFormOpener = (typeOfForm) => {
    setformToDisplay(typeOfForm);
    setIsModalOpen(true);
  };
  useEffect(() => {
    fetch();
  }, []);
  return (
    <div className=" border-t-4 border-[#b49132] sticky w-full top-0 bg-white !z-[1000]  shadow-2xl">
      <div className="flex justify-between  lg:justify-between items-center  lg:gap-5 xl:gap-20 lg:flex-row lg:w-[95%] w-[95%] mx-auto">
        <div className="w-[40%] lg:w-[10%] py-5 cursor-pointer " onClick={() => navigate("/")}>
          <img src={logo} alt="logo" />
        </div>
        <div className="w-full hidden lg:block ">
          <ul className="flex  lg:justify-evenly xl:justify-end 2xl:gap-10 lg:gap-2   gap-5 items-center ">
            <li>
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive ? "ActiveLink " : "InActiveLink"
                }
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/AboutUs/"}
                className={({ isActive }) =>
                  isActive ? "ActiveLink" : "InActiveLink"
                }
              >
                <Dropdown
                  menu={{
                    items,
                  }}

                  // trigger={['click']}
                >
                  <Space className=" hover:text-[#b49132] hover:font-[600] text-[16px] hover:underline hover:underline-offset-[6px] decoration-2 decoration-[#b49132]">
                    About Us
                    <DownOutlined />
                  </Space>
                </Dropdown>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/Products/"
                className={({ isActive }) =>
                  isActive ? "ActiveLink " : "InActiveLink"
                }
              >
                <div>
                  <Dropdown
                    menu={{
                      items: [
                        {
                          key: "1",
                          label: <Link to="Products/">Our Products</Link>,
                        },
                        {
                          key: "2",
                          label: (
                            <Link to="/Products/Brokerage_Dealing">
                            stock Brokerage & Dealing
                            </Link>
                          ),
                        },
                        {
                          key: "3",
                          label: (
                            <Link to="/Products/investment_advisory">
                              Investment Advisory
                            </Link>
                          ),
                        },
                        {
                          key: "5",
                          label: (
                            <Link to="Products/financial-Investments">
                              Financial Planning
                            </Link>
                          ),
                        },
                        {
                          key: "4",
                          label: (
                            <Link to="/Products/fixed_income_Investment">
                              Fixed Income Investment
                            </Link>
                          ),
                        },

                        
                      ],
                    }}
                  >
                    <Space className=" hover:text-[#b49132] hover:font-[600] text-[16px] hover:underline hover:underline-offset-[6px] decoration-2 decoration-[#b49132]">
                      Products & Services
                      <DownOutlined />
                    </Space>
                  </Dropdown>
                </div>
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                to="/what-we-do/"
                className={({ isActive }) =>
                  isActive ? "ActiveLink " : "InActiveLink"
                }
              >
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: "1",
                        label: <Link  to="/what-we-do/">What We Do</Link>,
                      },
                      {
                        key: "2",
                        label: (
                          <Link  to="/what-we-do/investment_advisory">
                            Investment Advisory
                          </Link>
                        ),
                      },
                      {
                        key: "3",
                        label: (
                          <Link  to="/what-we-do/fixed_income_Investment">
                            Fixed Income Investment
                          </Link>
                        ),
                      },
                      {
                        key: "4",
                        label: (
                          <Link  to="/what-we-do/Brokerage_Dealing">
                            Brokerage & Dealing
                          </Link>
                        ),
                      },
                    ],
                  }}
                >
                  <Space className=" hover:text-[#b49132] hover:font-[600] text-[16px] hover:underline hover:underline-offset-[6px] decoration-2 decoration-[#b49132]">
                    Services
                    <DownOutlined />
                  </Space>
                </Dropdown>
              </NavLink>
            </li> */}
            <li>
              <NavLink
                to="/Research/"
                className={({ isActive }) =>
                  isActive ? "ActiveLink " : "InActiveLink"
                }
              >
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: "1",
                        label: <Link to="/Research/">Current Price List</Link>,
                      },
                      {
                        key: "2",
                        label: (
                          <Link to="/Research/top_gainers">Top Gainers</Link>
                        ),
                      },
                      {
                        key: "3",
                        label: (
                          <Link to="/Research/top_losers">Top Losers</Link>
                        ),
                      },

                      {
                        key: "4",
                        label: (
                          <Link to="/Research/Company_Research">
                            Our Research
                          </Link>
                        ),
                      },
                      // {
                      //   key: "5",
                      //   label: <Link to="/all_news/">Market News</Link>,
                      // },
                    ],
                  }}
                >
                  <Space className=" hover:text-[#b49132] hover:font-[600] text-[16px] hover:underline hover:underline-offset-[6px] decoration-2 decoration-[#b49132]">
                    Research
                    <DownOutlined />
                  </Space>
                </Dropdown>
              </NavLink>
            </li>
            <li>
              <NavLink
                // to={`/IndividualAccount`}
                // className={({ isActive }) =>
                //   isActive ? "ActiveLink " : "InActiveLink"
                // }
                className="InActiveLink "
              >
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: "1",
                        label: (
                          <p className="text-white font-[600] text-[16px] hover:text-[#9d9d9d] flex justify-between items-center gap-5">
                            Online Registration <TiChevronRightOutline />
                          </p>
                        ),
                        children: [
                          {
                            key: "2",
                            label: (
                              <Link
                                to="IndividualAccount"
                                className="!text-white font-[500] text-[16px] hover:!text-[#9d9d9d]"
                              >
                                Individual Account
                              </Link>
                            ),
                          },
                          {
                            key: "3",
                            label: (
                              <Link
                                to="CorporateAccount"
                                className="!text-white font-[500] text-[16px] hover:!text-[#9d9d9d]"
                              >
                                Corporate Account
                              </Link>
                            ),
                          },
                          {
                            key: "4",
                            label: (
                              <Link
                                to="Joint"
                                className="!text-white font-[500] text-[16px] hover:!text-[#9d9d9d]"
                              >
                                Joint Account
                              </Link>
                            ),
                          },
                          {
                            key: "5",
                            label: (
                              <Link
                                to="Minor"
                                className="!text-white font-[500] text-[16px] hover:!text-[#9d9d9d]"
                              >
                                Minor Account
                              </Link>
                            ),
                          },
                          {
                            key: "6",
                            label: (
                              <Link
                                to="EstateAccount"
                                className="!text-white font-[500] text-[16px] hover:!text-[#9d9d9d]"
                              >
                                Estate Account
                              </Link>
                            ),
                          },
                        ],
                      },
                      {
                        key: "2",
                        label: (
                          <Link to={'/Individual_offline'} className="text-white font-[600] text-[16px] hover:text-[#9d9d9d] flex justify-between items-center gap-5">
                            Offline Registration 
                            {/* <TiChevronRightOutline /> */}
                          </Link>
                        ),
                        // children: [
                        //   {
                        //     key: "2",
                        //     label: (
                        //       <p
                        //         onClick={() => {
                        //           offlineFormOpener("Individual");
                        //         }}
                        //         className="!text-white font-[500] text-[16px] hover:!text-[#9d9d9d]"
                        //       >
                        //         Individual Account
                        //       </p>
                        //     ),
                        //   },
                        //   {
                        //     key: "3",
                        //     label: (
                        //       <p
                        //         onClick={() => {
                        //           offlineFormOpener("Corporate");
                        //         }}
                        //         className="!text-white font-[500] text-[16px] hover:!text-[#9d9d9d]"
                        //       >
                        //         Corporate Account
                        //       </p>
                        //     ),
                        //   },
                        //   {
                        //     key: "4",
                        //     label: (
                        //       <p
                        //         onClick={() => {
                        //           offlineFormOpener("Joint");
                        //         }}
                        //         className="!text-white font-[500] text-[16px] hover:!text-[#9d9d9d]"
                        //       >
                        //         Joint Account
                        //       </p>
                        //     ),
                        //   },
                        //   {
                        //     key: "5",
                        //     label: (
                        //       <p
                        //         onClick={() => {
                        //           offlineFormOpener("Minor");
                        //         }}
                        //         className="!text-white font-[500] text-[16px] hover:!text-[#9d9d9d]"
                        //       >
                        //         Minor Account
                        //       </p>
                        //     ),
                        //   },
                        //   {
                        //     key: "6",
                        //     label: (
                        //       <p
                        //         onClick={() => {
                        //           offlineFormOpener("Estate");
                        //         }}
                        //         className="!text-white font-[500] text-[16px] hover:!text-[#9d9d9d]"
                        //       >
                        //         Estate Account
                        //       </p>
                        //     ),
                        //   },
                        // ],
                      },
                    ],
                  }}
                >
                  <Space className=" hover:text-[#b49132] hover:font-[600] text-[16px] hover:underline hover:underline-offset-[6px] decoration-2 decoration-[#b49132]">
                    Registrations
                    <DownOutlined />
                  </Space>
                </Dropdown>
              </NavLink>
            </li>
            <li>
              <Dropdown
                menu={{
                  items: [
                    {
                      key: "2",
                      label: (
                        <Link
                          to={"Individual_offline"}
                          // onClick={() => {
                          //   offlineFormOpener("Individual");
                          // }}
                          className="!text-white font-[500] text-[16px] hover:!text-[#9d9d9d]"
                        >
                          Individual Account
                        </Link>
                      ),
                    },
                    {
                      key: "3",
                      label: (
                        <Link
                          to={"Corporate_offline"}
                          // onClick={() => {
                          //   offlineFormOpener("Corporate");
                          // }}
                          className="!text-white font-[500] text-[16px] hover:!text-[#9d9d9d]"
                        >
                          Corporate Account
                        </Link>
                      ),
                    },
                    {
                      key: "4",
                      label: (
                        <Link
                          to={"Joint_offline"}
                          // onClick={() => {
                          //   offlineFormOpener("Joint");
                          // }}
                          className="!text-white font-[500] text-[16px] hover:!text-[#9d9d9d]"
                        >
                          Joint Account
                        </Link>
                      ),
                    },
                    {
                      key: "5",
                      label: (
                        <Link
                          to={"Minor_offline"}
                          // onClick={() => {
                          //   offlineFormOpener("Minor");
                          // }}
                          className="!text-white font-[500] text-[16px] hover:!text-[#9d9d9d]"
                        >
                          Minor Account
                        </Link>
                      ),
                    },
                    {
                      key: "6",
                      label: (
                        <Link
                          to={"Estate_offline"}
                          // onClick={() => {
                          //   offlineFormOpener("Estate");
                          // }}
                          className="!text-white font-[500] text-[16px] hover:!text-[#9d9d9d]"
                        >
                          Estate Account
                        </Link>
                      ),
                    },
                  ],
                }}
              >
                <Space className=" InActiveLink">
                  Download
                  <DownOutlined />
                </Space>
              </Dropdown>
            </li>
            <li>
              <NavLink
                to="/Contact"
                className={({ isActive }) =>
                  isActive
                    ? "ActiveLink hover:text-[#b49132] "
                    : "InActiveLink hover:text-[#b49132]"
                }
              >
                Contact Us
                {/* <Dropdown
                  menu={{
                    items: [
                      {
                        key: "1",
                        label: <Link to="/Contact">Contact Us</Link>,
                      },
                    ],
                  }}
                >
                  <Space className=" hover:text-[#b49132] hover:font-[600] text-[16px] hover:underline hover:underline-offset-[6px] decoration-2 decoration-[#b49132]">
                    Contact Us
                    <DownOutlined />
                  </Space>
                </Dropdown> */}
              </NavLink>
            </li>
            <li></li>
          </ul>
        </div>

        {/* small screen  */}
        <div className=" lg:hidden  flex justify-center  items-center col-span-1 w-[55px] h-[45px] py-1 relative ">
          <IoIosMenu
            className="text-[45px] w-full h-full  text-end cursor-pointer text-white bg-[#b49132]  "
            onClick={() => setIsOpen(!isOpen)}
          />
        </div>
      </div>

      {isOpen ? (
        <div
          className="lg:hidden  block w-[100%] mx-auto  absolute top-0 h-[100vh] bg-[white] overflow-auto "
          data-aos="fade-left"
          data-aos-duration="300"
        >
          <div className="border pb-3">
            <div className="flex justify-between items-center  w-[95%] mx-auto sticky top-0 ">
              <div className=" w-[40%] py-3  " onClick={() => navigate("/")}>
                <img src={logo} alt="logo" />
              </div>
              <GrClose
                className="text-[28px]  text-end cursor-pointer  !text-[#b49132]  "
                onClick={() => setIsOpen(!isOpen)}
              />
            </div>
          </div>

          <div className="w-[95%] mx-auto pb-5 pt-3">
            <ul className="justify-start items-start flex gap-2 w-full flex-col">
              <li>
                <NavLink
                  onClick={() => setIsOpen(!isOpen)}
                  to="/"
                  className={({ isActive }) =>
                    isActive ? "ActiveLinkSmallScreen " : "InActiveLink"
                  }
                >
                  Home
                </NavLink>
              </li>
              <li className=" w-full">
                <Collapse
                  items={items2}
                  // defaultActiveKey={["1"]}
                  onChange={() => {}}
                  // bordered={false}
                  ghost
                  size="small"
                  expandIconPosition={"end"}
                  expandIcon={({ isActive }) => (
                    <HiPlusSm className="!text-[20px] !text-[grey]" />
                  )}
                />
              </li>
              <li>
                <NavLink
                  onClick={() => setIsOpen(!isOpen)}
                  to="/Contact"
                  className={({ isActive }) =>
                    isActive ? "ActiveLinkSmallScreen " : "InActiveLink"
                  }
                >
                  Contact Us
                </NavLink>
              </li>
            </ul>
          </div>
          {/* <ul className="flex flex-col justify-center items-start gap-5 p-0 leading-5">
            <li>
              <NavLink
                onClick={() => setIsOpen(!isOpen)}
                to="/"
                className={({ isActive }) =>
                  isActive ? "ActiveLinkSmallScreen " : "InActiveLink"
                }
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={() => setIsOpen(!isOpen)}
                to="/AboutUs/"
                className={({ isActive }) =>
                  isActive ? "ActiveLinkSmallScreen" : "InActiveLink"
                }
              >
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: "1",
                        label: <Link to="AboutUs/">Who We Are</Link>,
                      },
                      {
                        key: "2",
                        label: <Link to="AboutUs/OurCompany">Our Company</Link>,
                      },
                      {
                        key: "3",
                        label: <Link to="AboutUs/backGround">BackGround</Link>,
                      },
                      {
                        key: "4",
                        label: (
                          <Link to="AboutUs/management_team">
                            Management Team
                          </Link>
                        ),
                      },
                      {
                        key: "5",
                        label: (
                          <Link to="AboutUs/Boards">Board of directors</Link>
                        ),
                      },
                    ],
                  }}

                  // trigger={['click']}
                >
                  <Space className=" hover:text-[#b49132] hover:font-[600] text-[18px]  ">
                    About Us
                    <DownOutlined />
                  </Space>
                </Dropdown>
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={() => setIsOpen(!isOpen)}
                to="/Products/"
                className={({ isActive }) =>
                  isActive ? "ActiveLinkSmallScreen " : "InActiveLink"
                }
              >
                <div>
                  <Dropdown
                    menu={{
                      items: [
                        {
                          key: "1",
                          label: <Link to="/Products/">Our Products</Link>,
                        },
                        {
                          key: "2",
                          label: (
                            <Link to="/Products/portfolio_management">
                              Portflio Management
                            </Link>
                          ),
                        },
                        {
                          key: "3",
                          label: (
                            <Link to="/Products/alternative-investments">
                              Alternative Investments
                            </Link>
                          ),
                        },
                        {
                          key: "4",
                          label: (
                            <Link to="/Products/financial-Investments">
                              Financial Planning
                            </Link>
                          ),
                        },
                        {
                          key: "5",
                          label: (
                            <Link to="/Products/Stoking-broking">
                              Stockbroking
                            </Link>
                          ),
                        },
                      ],
                    }}
                  >
                    <Space className=" hover:text-[#b49132] hover:font-[600] text-[16px] ">
                      Products
                      <DownOutlined />
                    </Space>
                  </Dropdown>
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={() => setIsOpen(!isOpen)}
                to="/what-we-do/"
                className={({ isActive }) =>
                  isActive ? "ActiveLinkSmallScreen " : "InActiveLink"
                }
              >
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: "1",
                        label: <Link to="/what-we-do/">What We Do</Link>,
                      },
                      {
                        key: "2",
                        label: (
                          <Link to="/what-we-do/investment_advisory">
                            Investment Advisory
                          </Link>
                        ),
                      },
                      {
                        key: "3",
                        label: (
                          <Link to="/what-we-do/fixed_income_Investment">
                            Fixed Income Investment
                          </Link>
                        ),
                      },
                      {
                        key: "4",
                        label: (
                          <Link to="/what-we-do/Brokerage_Dealing">
                            Brokerage & Dealing
                          </Link>
                        ),
                      },
                    ],
                  }}
                >
                  <Space className=" hover:text-[#b49132] hover:font-[600] text-[16px] ">
                    Services
                    <DownOutlined />
                  </Space>
                </Dropdown>
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={() => setIsOpen(!isOpen)}
                to="/Research/"
                className={({ isActive }) =>
                  isActive ? "ActiveLinkSmallScreen " : "InActiveLink"
                }
              >
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: "1",
                        label: <Link to="/Research/">Current Price List</Link>,
                      },
                      {
                        key: "2",
                        label: (
                          <Link to="/Research/top_gainers">Top Gainers</Link>
                        ),
                      },
                      {
                        key: "3",
                        label: (
                          <Link to="/Research/top_losers">Top Losers</Link>
                        ),
                      },

                      {
                        key: "4",
                        label: (
                          <Link to="/Research/Company_Research">
                            Our Research
                          </Link>
                        ),
                      },
                      {
                        key: "5",
                        label: <Link to="/all_news/">Market</Link>,
                      },
                    ],
                  }}
                >
                  <Space className=" hover:text-[#b49132] hover:font-[600] text-[16px] ">
                    Research
                    <DownOutlined />
                  </Space>
                </Dropdown>
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={() => setIsOpen(!isOpen)}
                to="/all_news/"
                className={({ isActive }) =>
                  isActive ? "ActiveLinkSmallScreen " : "InActiveLink"
                }
              >
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: "1",
                        label: <Link to="/all_news/">Latest News</Link>,
                      },
                      {
                        key: "2",
                        label: <Link to="/all_news/downloads">Downloads</Link>,
                      },
                      {
                        key: "3",
                        label: <Link to="/all_news/glossary">Glossary</Link>,
                      },
                    ],
                  }}
                >
                  <Space className=" hover:text-[#b49132] hover:font-[600] text-[16px] ">
                    Media
                    <DownOutlined />
                  </Space>
                </Dropdown>
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={() => setIsOpen(!isOpen)}
                to="/Contact"
                className={({ isActive }) =>
                  isActive ? "ActiveLinkSmallScreen " : "InActiveLink"
                }
              >
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: "1",
                        label: <Link to="/Contact">Contact Us</Link>,
                      },
                    ],
                  }}
                >
                  <Space className=" hover:text-[#b49132] hover:font-[600] text-[16px] ">
                    Contact Us
                    <DownOutlined />
                  </Space>
                </Dropdown>
              </NavLink>
            </li>
            <li></li>
          </ul> */}
        </div>
      ) : null}

      <RegistrationModal
        ismodalOpen={ismodalOpen}
        setIsModalOpen={setIsModalOpen}
        formToDisplay={formToDisplay}
      />
    </div>
  );
};

export default Navbar;
