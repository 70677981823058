import React from "react";
import { Link } from "react-router-dom";
import firstIcon from '../../Assets/Icons/5.png'
import secondIcon from '../../Assets/Icons/2.png'
import thirdIcon from '../../Assets/Icons/6.png'
import lastIcon from '../../Assets/Icons/3.png'
import bookmarkIcon from '../../Assets/Icons/13.png'


const ProductPage = () => {
  return (
    <div className="w-full flex flex-col gap-5 ">
       <div className="flex flex-col gap-5 text-[18px] text-justify leading-loose text-[#424242]">
        <p className="flex justify-center items-start flex-col  lg:text-[30px] text-[24px] leading-loose  font-bold text-[#373c5f] capitalize">
          Products We Offer
          <span className="border-[1px] border-[#b49132] w-[5%]"></span>
        </p>
        <div className="grid md:grid-cols-3 grid-cols-2  justify-between items-center gap-10">

         

          <div className="flex  justify-start gap-2 items-center">
            <div className="border-2 lg:p-4 p-2 border-[#b49132]">
            <img src={secondIcon} alt=""  />
            </div>
           
            <Link to={'/Products/financial-Investments'} className="">	Financial Planning</Link>
          </div>

         

          {/* <div className="flex  justify-start gap-2 items-center">
            <div className="border-2 lg:p-4 p-2 border-[#b49132]">
            <img src={lastIcon} alt=""  />
            </div>
           
            <Link to={'/Products/Stoking-broking'} className="">	Stock Broking</Link>
          </div> */}


          <div className="flex  justify-start gap-2 items-center">
            <div className="border-2 lg:p-4 p-2 border-[#b49132]">
            <img src={bookmarkIcon} alt=""  />
            </div>
           
            <Link className="flex justify-center items-center gap-1" to='/Products/investment_advisory'>Investment Advisory</Link>
          </div>

         
          <div className="flex  justify-start gap-2 items-center">
            <div className="border-2 lg:p-4 p-2 border-[#b49132]">
            <img src={thirdIcon} alt=""  />
            </div>
           
            <Link className="flex justify-center items-center gap-1" to='/Products/Brokerage_Dealing'>stock Brokerage & Dealing	</Link>
          </div>

          <div className="flex  justify-start gap-2 items-center">
            <div className="border-2 lg:p-4 p-2 border-[#b49132]">
            <img src={lastIcon} alt=""  />
            </div>
           
            <Link className="flex justify-center items-center gap-1" to='/Products/fixed_income_Investment'>Fixed Income Investment</Link>
          </div>
        </div>
      </div>

      <div className="text-[18px] text-justify leading-loose text-[#424242]">
        <p className="flex justify-center items-start flex-col  lg:text-[30px] text-[24px] leading-loose  font-bold text-[#373c5f] capitalize">
          Our Products
          <span className="border-[1px] border-[#b49132] w-[5%]"></span>
        </p>
        <p className="flex gap-5 flex-col pt-8">
          Three different strategies for the person you are today and who you
          wil be tomorrow.{" "}
          <span>
            We won’t confuse you with tons of products; we realize you have one
            of three objectives – to preserve the value of your investments in
            real terms, to grow capital and to meet your current obligations –
            that’s why we offer three simple solutions to meet whatever
            complexities or questions life may throw at you
          </span>
        </p>
      </div>

      <div className="flex flex-col gap-5 text-[18px] text-justify leading-loose text-[#424242]">
        <p className="flex justify-center items-start flex-col  lg:text-[30px] text-[24px] leading-loose  font-bold text-[#373c5f] capitalize">
          Return on Investments
          <span className="border-[1px] border-[#b49132] w-[5%]"></span>
        </p>
        <p>
          We create synthesized investment products with equity, money market
          and fixed income assets to create return on investment that produces
          good returns against inflation. You can use our products to build up
          your retirement fund, meet a goal such as education financing or meet
          regular payments.
        </p>
      </div>

      <div className="flex flex-col gap-5 text-[18px] text-justify leading-loose text-[#424242]">
        <p className="flex justify-center items-start flex-col  lg:text-[30px] text-[24px] leading-loose  font-bold text-[#373c5f] capitalize">
          Aspiration investments
          <span className="border-[1px] border-[#b49132] w-[5%]"></span>
        </p>
        <p>
          You may have some aspirations which you are prepared to take a higher
          level of risk without affecting your day to day life style. Our
          aspiration financial products will offer significant return on the
          upside while providing a safety threshold on the downside. You can use
          this to finance luxury asset acquisitions, holidays or simply save
          towards starting your new business.
        </p>
      </div>

      <div className="flex flex-col gap-5 text-[18px] text-justify leading-loose text-[#424242]">
        <p className="flex justify-center items-start flex-col  lg:text-[30px] text-[24px] leading-loose text-start font-bold text-[#373c5f] capitalize">
          Balanced return investments
          <span className="border-[1px] border-[#b49132] w-[5%]"></span>
        </p>
        <p>
          A bit of this and a bit of that. Our balanced return ensures asset
          safety with opportunities to take advantage of capital growth. Use
          this to meet your medium term goals and objectives without fuss.
        </p>
      </div>

     
    </div>
  );
};

export default ProductPage;
