import { Modal } from "antd";
import { useState } from "react";
import directSettlement from "../../Assets/offlineForm/directsettlement.pdf";
import joint from "../../Assets/offlineForm/HCML kyc Joint account .pdf";

import minor from "../../Assets/offlineForm/HCML kyc Minor Account .pdf";

import transferOfStock from "../../Assets/offlineForm/Intermember Form-hcd - Transfer of Stocks form.pdf";
import TransferForm from "../../Assets/offlineForm/TRANSFER FORM.pdf";

const RegistrationModal = ({ ismodalOpen, setIsModalOpen, formToDisplay }) => {
  
  return (
    <Modal
      open={ismodalOpen}
      centered={true}
      // closeIcon={null}
      // onOk={() => approveTransactionHandler(TransactionDetails.code)}
      onCancel={() => setIsModalOpen(!ismodalOpen)}
      footer={null}
      className=" !w-[80%] !h-[90%]"
    >
      <div className="p-3  !w-full !h-[100vh]">
        <iframe
          src={`${
            formToDisplay === "Individual"
              ? directSettlement
              : formToDisplay === "Corporate"
              ? TransferForm
              : formToDisplay === "Joint"
              ? joint
              : formToDisplay === "Minor"
              ? minor
              : formToDisplay === "Estate"
              ? transferOfStock
              : null
          }`}
          title={formToDisplay}
          className="!h-full !w-full"
        ></iframe>
      </div>
    </Modal>
  );
};

export default RegistrationModal;
