import React from 'react'
import { useParams } from 'react-router-dom';
import directSettlement from "../../Assets/offlineForm/directsettlement.pdf";
import joint from "../../Assets/offlineForm/HCML kyc Joint account .pdf";

import individualAcctForm from "../../Assets/offlineForm/HCML kyc Minor Account .pdf";

import transferOfStock from "../../Assets/offlineForm/Intermember Form-hcd - Transfer of Stocks form.pdf";
import TransferForm from "../../Assets/offlineForm/TRANSFER FORM.pdf";
import coporateAcct from '../../Assets/offlineForm/HCML kyc corporate .pdf'
import estate from '../../Assets/offlineForm/HCML kyc ESTATE ACCOUNT-1.pdf'


const Offlineform = () => {
  const {id} = useParams();
 //console.log(id)
  return (
    <div>
         <div className="p-3  !w-[90%] mx-auto !h-[100vh] ">
        <iframe
          src={`${
            id === "Individual_offline"
              ? individualAcctForm
              : id === "Corporate_offline"
              ? coporateAcct
              : id === "Joint_offline"
              ? joint
              : id === "Minor_offline"
              ? individualAcctForm
              : id === "Estate_offline"
              ? estate
              : <div className='flex justify-center items-center'>
                <p className='text-lg text-[red] '>no form Available !</p>
              </div>
          }`}
          title={id}
          className="!h-full !w-full"
        ></iframe>
      </div>
    </div>
  )
}

export default Offlineform
