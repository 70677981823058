import { Link, NavLink, Outlet, useLocation } from "react-router-dom";
import AboutBanner from "../../Assets/about-banner2.jpg";
import slide1 from "../../Assets/slides/slide-1.jpg";
import { Table } from "antd";
import "./About.css";
import QuoteComponent from "../../components/QuoteComponent/QuoteComponent";
import CurrencyVsPriceComponent from "../../components/CurrencyVsPrice/CurrencyVsPrice.Component";
import { TiChevronRightOutline } from "react-icons/ti";
const AboutUsParentPage = () => {
  const location = useLocation();
  
  const dataSource = [
    {
      key: "1",
      Currency: "Us Dollar",
      Buy: 753.86,
      Central: 754.86,
      Sell: 753.86,
    },
    {
      key: "2",
      Currency: "Pounds Sterling",
      Buy: 947.9,
      Central: 947.63,
      Sell: 948.9,
    },
    {
      key: "3",
      Currency: "Euro",
      Buy: 808,
      Central: 809.89,
      Sell: 809.0,
    },
  ];

  const columns = [
    {
      title: "Currency",
      dataIndex: "Currency",
      key: "Currency",
    },
    {
      title: "Buy",
      dataIndex: "Buy",
      key: "Buy",
    },
    {
      title: "Central",
      key: "Central",
      dataIndex: "Central",
    },
    {
      title: "Sell",
      dataIndex: "Sell",
      key: "Sell",
    },
  ];
  return (
    <div>
      <div
        className="bg-cover bg-[center] bg-no-repeat flex justify-center flex-col items-center h-[45vh] gap-10"
        style={{
          backgroundImage: `url(${
            location.pathname === "/AboutUs/management_team" ||  location.pathname === "/AboutUs/Boards"
              ? slide1
              : AboutBanner
          })`,
        }}
      >
        <p className="  text-[41px]  text-white font-[400] flex_center flex-col">
          {location.pathname === "/AboutUs/"
            ? "About Us"
            : location.pathname === "/AboutUs/OurCompany"
            ? "Company Brief"
            
            : location.pathname === "/AboutUs/management_team"
            ? "Management"
            : location.pathname === "/AboutUs/Boards"?'Board Of Directors' : null}
          <span className="border-[1px] w-[20%]"></span>
        </p>

        <ul className="flex_center text-white gap-3 font-[600] leading-loose">
          <li >
            <Link className="flex justify-center items-center gap-1" to="/">Home <TiChevronRightOutline /></Link>
          </li>
          <li >
            <Link className="flex justify-center items-center gap-1" to="/AboutUs/">About Us <TiChevronRightOutline /></Link>
          </li>
          <li >
            <Link>
              {location.pathname === "/AboutUs/"
                ? null
                : location.pathname === "/AboutUs/OurCompany"
                ? "Company Brief"
                : location.pathname === "/AboutUs/backGround"
                ? "Company Background"
                : location.pathname === "/AboutUs/management_team"
                ? "Management Team"
                                : location.pathname === "/AboutUs/Boards"? 'Board Of Directors': null
              }
            </Link>
          </li>
        </ul>
      </div>

      <div className="flex w-[85%] gap-8 pt-8 mx-auto lg:flex-row flex-col ">
        <Outlet />
        <div className="flex flex-col   lg:w-[35%]  w-full gap-5">
          <div className="">
            <p className="border flex flex-col  bg-[#f4f4f4] ">
              <NavLink
                to="/AboutUs/"
                className={({ isActive }) =>
                  isActive
                    ? "bg-[#b49132] font-[600] border-b border-grey px-2  py-4 text-white"
                    : "border-b border-grey px-2  py-4 hover:text-[white] text-[16px] font-[400] text-[#373c5f] hover:bg-[#b49132]"
                }
              >
                About Us
              </NavLink>
              <NavLink
                to="/AboutUs/OurCompany"
                className={({ isActive }) =>
                  isActive
                    ? "bg-[#b49132] font-[600] border-b border-grey px-2  py-4 text-white"
                    : "border-b border-grey px-2  py-4 hover:text-[white] text-[16px] font-[400] text-[#373c5f] hover:bg-[#b49132]"
                }
              >
                Company Brief
              </NavLink>
             
              <NavLink
                to="/AboutUs/management_team"
                className={({ isActive }) =>
                  isActive
                    ? "bg-[#b49132] font-[600] border-b border-grey px-2  py-4 text-white"
                    : "border-b border-grey px-2  py-4 hover:text-[white] text-[16px] font-[400] text-[#373c5f] hover:bg-[#b49132]"
                }
              >
                Management Team
              </NavLink>
              <NavLink
                to="/AboutUs/Boards"
                className={({ isActive }) =>
                  isActive
                    ? "bg-[#b49132] font-[600] border-b border-grey px-2  py-4 text-white"
                    : "border-b border-grey px-2  py-4 hover:text-[white] text-[16px] font-[400] text-[#373c5f] hover:bg-[#b49132]"
                }
              >
                Board of Directors
              </NavLink>
            </p>
          </div>
          {/* <div className="dave flex gap-1 flex-col">
            <p className="text-[#b49132] text-2xl">Forex - CBN Rates</p>
            <Table
              dataSource={dataSource}
              columns={columns}
              pagination={false}
              size="small"
              className="b"
            />
            <p>
              Source : CBN.{" "}
              <span className="text-[#b49132] pb-2 leading-loose decoration-[#b49132] decoration-dotted underline">
                View More
              </span>
            </p>
          </div> */}

          {/* <CurrencyVsPriceComponent/> */}
          {location.pathname === "/AboutUs/management_team" || location.pathname === "/AboutUs/Boards"? (
            //  <div>
            <QuoteComponent />
          ) : // </div>
          null}
        </div>
      </div>
      <div className=" w-[full] bg-[#b49132] text-white  flex justify-center items-center mt-8">
        <div className="flex justify-between items-center w-[85%] mx-auto py-12 flex-col md:flex-row text-center">
          <p className="flex flex-col text-[21px] leading-[1.8] capitalize">
            manage your Investment with more confidence.{" "}
            <span className="text-[18px]">
              Open a trading Account today and kickstart your investments
            </span>
          </p>
          <button className="rounded px-8 bg-white text-[#424242] py-2 text-[16px] uppercase leading-loose hover:bg-[#313140] hover:text-white ease-in-out duration-[0.5s]">
            Get Started
          </button>
        </div>
      </div>
    </div>
  );
};

export default AboutUsParentPage;
